import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from './Layout';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';

import Seo from 'gatsby-plugin-wpgraphql-seo';
import PostContent from '../components/post/PostFlexibleContent';
import PostMasthead from '../components/post/PostMasthead';
import { Facebook, LinkedIn, Twitter } from '../components/elements/Icon';

import RelatedPosts from '../components/flexible/RelatedPosts';
import parse from 'html-react-parser';
import translate, { getTranslatedCategory } from '~/utils/translate';

export default function ArticlePage({
  data: { wpPage, wpPost, wp },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const { blogPost } = wpPost;
  const { blogHero, flexiblePostContent } = blogPost;
  // const category = getTranslatedCategory(wpPost.categories?.nodes[0])?.name || '';
  const category = wpPost.categories?.nodes[0]?.name || '';

  if (!blogHero.heading) blogHero.heading = wpPost.title;
  const stripShortcodeRegex = /\[.+\]/g;


  return (
    <Layout wp={wp}>
      <Seo post={wpPost} title={wpPost.title} />

      <PostMasthead {...blogHero} author={wpPost.author} post={wpPost}  date={wpPost?.dateFormat} category={category} />

        <div>
          {flexiblePostContent ? (
            <PostContent post={wpPost} content={flexiblePostContent} />
          ) : (
            <div className="prose erwe post-text max-w-[792px] mx-auto">
              {parse(wpPost.content ? wpPost.content.replace(stripShortcodeRegex, '').replace(/data-src/g, 'src') : '')}
            </div>
          )}
        </div>
      {blogPost?.relatedPosts > 0 && <RelatedPosts posts={blogPost?.relatedPosts} heading={translate('Related Webinars')} />}
    </Layout>
  );
}

export const pageQuery = graphql`
  query Article($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      ...GeneratedWpPost
      categories {
        nodes {
          name
        }
      }
      author {
        node {
            firstName
            email
            slug
            name
            nicename
            nickname
            lastName
            avatar {
              url
            }
        }
      }
      dateFormat: date(formatString: "Do MMMM YYYY")
    }
    wp {
      ...GeneratedWp
    }
  }
`;
